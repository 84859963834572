<template>
  <div class="ResponsiveContentBoard">
    <div v-if="!unityEdit">
      <div class="mainTitle textAverage">Lista de Unidades</div>
      <div class="inputContainer">
        <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="unitySearch">
        <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
      </div>
    </div>
    <div v-if="!unityEdit" class="tableArea">
      <div class="tableInternalArea">
        <table class="tableNormal minSize textSmall">
          <tr>
            <th class="ResponsiveTableHead">Unidade</th>
            <th class="ResponsiveTableHead">CNPJ</th>
            <th class="ResponsiveTableHead">Cliente</th>
            <th class="ResponsiveTableHead btnColumn"></th>
          </tr>
          <tr v-for="(unity, index) in unityListFilter" :key="index">
            <td>{{unity.name}}</td>
            <td>{{unity.cnpj}}</td>
            <td v-if="unity.client != null">{{unity.client.name}}</td>
            <td v-else>{{'-'}}</td>
            <td class="ResponsiveCellConfig">
              <div @click="editUnity(unity)" class="btnIcon btnSmallSize btnTypeSave">
                <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <UnityEdit v-else :unity="unity" :clientEdit="false" :newUnity="newUnity" @close="closeUnityEdit()"></UnityEdit>
    <div v-if="!unityEdit" class="ResponsiveBtnConfigArea">
      <button @click="newUnityBtn()" @close="closeUnityEdit()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Novo</button>
      <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
    </div>
    <PopUp msg="Carregando lista de unidades..." :show="load" type="Loader"/>
    <PopUp :msg="faultMsg" :show="faultOnLoadUnities" type="Fault" :onOk="popUpOk"/>
  </div>
</template>

<script>
  import PopUp from '@/views/PopUp/PopUp.vue'
  import UnityEdit from "./UnityEdit"
  import UnityDAO from '@/sys/Control/Unity/UnityDAO'

  export default {
    components: {UnityEdit, PopUp},
    methods: {
      async closeUnityEdit(){
        this.unity = null
        this.unityEdit = false
        this.unityList = await UnityDAO.readAllActiveUnities();
      },
      newUnityBtn(){
        this.unityEdit = true
        this.newUnity = true
      },
      editUnity(unity) {
        this.unity = unity
        this.newUnity = false
        this.unityEdit = true
      },
      close(){
        this.$router.push({path: '/Setting/ChooseSetting'})
      },
      popUpOk: function(){
        this.faultOnLoadUnities=false;
      }
    },
    computed: {
      unityListFilter(){
        if(this.unityList != null) {
          return this.unityList.filter(unity => {
            if(unity.client != null) {
              return unity.client.name.toLowerCase().includes(this.unitySearch.toLowerCase())
                || unity.name.toLowerCase().includes(this.unitySearch.toLowerCase())
                || unity.cnpj.includes(this.unitySearch)
            } else {
              return unity.name.toLowerCase().includes(this.unitySearch.toLowerCase())
                || unity.cnpj.includes(this.unitySearch)
            }
          })
        }
      }
    },
    watch: {
      unityList() {
        if(this.unityList != null) {
          this.load = false;
        }
      }
    },
    data() {
      return {
        load: true,
        unityList: null,
        unityEdit: false,
        unity: null,
        newUnity: false,
        unitySearch: '',
        faultOnLoadUnities: false,
        faultMsg: null
      };
    },
    async created() {
      this.unityList = await UnityDAO.readAllActiveUnities().catch(e=>{
        this.load = false;
        this.faultMsg=e;
        this.faultOnLoadUnities=true;
      });
    }
  };
</script>

<style scoped>
  .ResponsiveContentBoard {
    flex-flow: column nowrap;
  }
  .tableArea {
    flex-grow: 1;/* Define que a tabela é o unico item que pode mudar de tamanho */
    position: relative;
  }
  .tableInternalArea {
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 10px;/*Usado como paddin da tabela */
    right: 10px;/*Usado como paddin da tabela */
    background-color: #1e2227;
    overflow: auto;
  }
  @media(max-width: 825px){
    .tableInternalArea {
      top: 10px;
      left: 5px;/*Usado como paddin da tabela */
      right: 5px;/*Usado como paddin da tabela */
    }
  }
</style>